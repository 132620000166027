"use client";

import { MyLink } from "@/components/MyLink";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";
import {
  MouseEventHandler,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import {
  articleCategories,
  industories,
  newsCategories,
  serviceCategories,
  companyService,
  articleBasicCategories,
} from "@/constants/constants";
import { Post, PostDirectory } from "@/types/types";
import {
  createResizedImageUrl,
  getCategoryInfo,
  getIndustoryInfo,
} from "@/utils/utils";

type HoverSpaceProps = {
  firstCategoryName: string;
  categories: { name: string; category: string }[];
  allPosts: Post[];
  dir: PostDirectory;
  onMouseOver: MouseEventHandler<HTMLDivElement>;
  onMouseLeave: MouseEventHandler<HTMLDivElement>;
};

const HoverSpace = (props: HoverSpaceProps) => {
  const {
    firstCategoryName,
    categories,
    allPosts,
    dir,
    onMouseOver,
    onMouseLeave,
  } = props;
  const [selectedCategory, setSelectedCategory] = useState<{
    name: string;
    category: string;
  }>(categories[0]);
  const filterdPosts =
    firstCategoryName === "業種別カテゴリ"
      ? allPosts.filter(
          (item) =>
            item.data.SecondCate === selectedCategory.category ||
            item.data.SecondCate?.includes(selectedCategory.category)
        )
      : allPosts.filter(
          (item) =>
            item.data.SecondCate === selectedCategory.category ||
            item.data.SecondCate?.includes(selectedCategory.category)
        );

  return (
    <div
      className={styles.hoverSpace}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.hoverSpaceInner}>
        <ul className={styles.hoverNavList}>
          {categories.map((item) => {
            return (
              <li className={styles.hoverNavListItem} key={item.name}>
                <MyLink
                  href={`/${dir}/category/${item.category}`}
                  className={
                    item.name === selectedCategory.name
                      ? clsx(styles.hoverNavListButton, styles.isActive)
                      : styles.hoverNavListButton
                  }
                  onMouseOver={() => {
                    setSelectedCategory(item);
                  }}
                >
                  {item.name}
                </MyLink>
              </li>
            );
          })}
        </ul>
        <ul className={styles.hoverPostList}>
          {filterdPosts.map((post, index) => {
            const path = `/${dir}/${post.slug}`;
            const categoryInfo =
              dir !== "case" && dir !== "resources"
                ? getCategoryInfo(post.data.SecondCate, dir)
                : null;
            const industoryInfo =
              dir === "case" ? getIndustoryInfo(post.data.SecondCate) : null;
            const imgSrc =
              dir === "article" &&
              post.data.featureImg.startsWith(
                "https://aisouken.blob.core.windows.net/article/"
              )
                ? createResizedImageUrl(
                    post.data.featureImg,
                    400, // width
                    225 // height
                  )
                : post.data.featureImg;
            return (
              <li className={styles.hoverPostItem} key={index}>
                <MyLink href={path} className={styles.hoverPostImage}>
                  <div className="c-image-wrap">
                    <Image
                      src={imgSrc || "/sample.png"}
                      alt={post.data.title}
                      fill
                    />
                  </div>
                </MyLink>
                <div className={styles.hoverPostCateories}>
                  {categoryInfo &&
                    categoryInfo.map((item) => (
                      <MyLink
                        href={`/${dir}/category/${item.secondCategoryEn}`}
                        className={styles.hoverPostTop}
                        key={item.secondCategoryEn}
                      >
                        {`${item.firstCategoryJa}/${item.secondCategoryJa}`}
                      </MyLink>
                    ))}
                  {industoryInfo &&
                    industoryInfo.map((item) => (
                      <MyLink
                        href={`/case/category/${item.category}`}
                        className={styles.hoverPostTop}
                        key={item.category}
                      >
                        {item.name}
                      </MyLink>
                    ))}
                </div>
                <MyLink href={path}>
                  <h2 className={styles.hoverPostTitle}>{post.data.title}</h2>
                </MyLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

type PopupServiceMenuProps = {
  onMouseOver: MouseEventHandler<HTMLDivElement>;
  onMouseLeave: MouseEventHandler<HTMLDivElement>;
};

const PopupServiceMenu = ({
  onMouseOver,
  onMouseLeave,
}: PopupServiceMenuProps) => {
  const router = useRouter();

  const handleButtonClick = (url: string) => {
    router.push(url);
  };
  return (
    <div
      className={styles.popupMenuContainer}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <p>AI総研のサービス8つ</p>
      <div className={styles.popupMenuInner}>
        <div className={styles.popupItem}>
          {companyService.map((service, index) => (
            <button
              key={service.name}
              className={styles.popupButton}
              style={{ borderTop: index < 2 ? "1px solid #e6e6e6" : "none" }}
              onClick={() => handleButtonClick(service.url)}
            >
              <span>{service.name}</span>
              <div className={styles.buttonIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                  width="24px"
                  height="24px"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

type spMenuProps = {
  spMenuRef: RefObject<HTMLDivElement>;
};

const SPMenu = (props: spMenuProps) => {
  const { spMenuRef } = props;
  return (
    <div className={styles.spMenu} ref={spMenuRef}>
      <div className={styles.spMenuInner}>
        {/*<a href="tel:0120-03-4150" className={styles.spMenuTel}>
          <span>
            お電話からの
            <br />
            お問い合わせ
          </span>
          <span>0120-03-4150</span>
        </a>*/}

        <ul className={styles.spMenuList}>
          <li className={styles.spMenuListItemTop}>
            <MyLink href="/" className={styles.spMenuListButton}>
              <p>ホーム</p>
              <div className={styles.buttonIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                  width="24px"
                  height="24px"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </MyLink>
          </li>
          <li className={styles.spMenuListItem}>
            <MyLink href="/service" className={styles.spMenuListButton}>
              <p>サービス</p>
              <div className={styles.buttonIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                  width="24px"
                  height="24px"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </MyLink>
          </li>
          <li className={styles.spMenuListItem}>
            <MyLink href="/case" className={styles.spMenuListButton}>
              <p>事例</p>
              <div className={styles.buttonIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                  width="24px"
                  height="24px"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </MyLink>
          </li>
          <li className={styles.spMenuListItem}>
            <MyLink href="/article" className={styles.spMenuListButton}>
              <p>メソッド</p>
              <div className={styles.buttonIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                  width="24px"
                  height="24px"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </MyLink>
          </li>
          <li className={styles.spMenuListItem}>
            <MyLink href="/company-info" className={styles.spMenuListButton}>
              <p>会社紹介</p>
              <div className={styles.buttonIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                  width="24px"
                  height="24px"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </MyLink>
          </li>

          {/* <li className={styles.spMenuListItem}>
            <MyLink href="/contact" className={styles.spMenuListButton}>
              お問い合わせ
            </MyLink>
          </li>
          <li className={styles.spMenuListItem}>
          <MyLink href="" className={styles.spMenuListButton}>
            掲載希望の企業様はこちら
          </MyLink>
        </li>
          <li className={styles.spMenuListItem}>
            <details className={styles.details}>
              <summary className={styles.spMenuListButton}>
                <div>
                  カテゴリーから探す
                  <Image
                    src="/icon/arrow-icon.svg"
                    alt=""
                    width={17}
                    height={19}
                    className={styles.summaryArrow}
                  />
                </div>
              </summary>
              <ul className={styles.spMenuCategoryList}>
                {articleCategories.map((firstCategory) => {
                  return (
                    <li
                      className={styles.firstListItem}
                      key={firstCategory.name}
                    >
                      <p className={styles.categoryHeader}>
                        {firstCategory.name}
                      </p>
                      <ul>
                        {firstCategory.secondCategories.map(
                          (secondCategory) => {
                            return (
                              <li
                                className={styles.secondListItem}
                                key={secondCategory.category}
                              >
                                <MyLink
                                  href={`/article/category/${secondCategory.category}`}
                                  className={styles.sideListButton}
                                >
                                  {secondCategory.name}
                                </MyLink>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </details>
          </li> */}
        </ul>

        {/* <MyLink href="/contact" className={styles.spMenuContact}>
          <span>秘密厳守</span>
          <span>WEBからお問い合わせ</span>
        </MyLink> */}
        <div className={styles.spMenuButtonInner}>
          <MyLink href="/resources" className={styles.spMenuDocument}>
            資料請求
          </MyLink>
          <MyLink href="/contact" className={styles.spMenuDocument}>
            ご相談
          </MyLink>
          <MyLink href="/mail-magazine" className={styles.spMenuDocument}>
            メルマガ登録
          </MyLink>
        </div>
      </div>
    </div>
  );
};

export const Header = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [isGrayDown, setIsGrayDown] = useState(false);
  const [dir, setDir] = useState<"article" | "case" | "news" | "service">(
    "article"
  );
  const [categories, setCategories] = useState<any[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [mouseLeft, setMouseLeft] = useState(true);

  useEffect(() => {
    setShowPopup(false);
    setMouseLeft(true);
  }, [pathname]);

  const handleMouseOverServices = () => {
    if (mouseLeft) {
      setShowPopup(true);
      setIsGrayDown(true);
    }
  };

  const handleMouseLeaveServices = () => {
    setShowPopup(false);
    setIsGrayDown(false);
    setMouseLeft(true);
  };

  useEffect(() => {
    switch (dir) {
      case "article":
        setCategories(articleCategories);
        break;
      case "case":
        setCategories([]);
        break;
      case "news":
        setCategories(newsCategories);
        break;
      case "service":
        setCategories(serviceCategories);
        break;
    }
  }, [dir]);

  const onMouseOverLink = (
    index: number,
    dir: "article" | "case" | "news" | "service"
  ) => {
    setDir(dir);
    setHoverControl((prev) =>
      prev.map((item, _index) => {
        return index === _index ? { ...item, link: true } : item;
      })
    );
    setIsGrayDown(true);
  };

  useEffect(() => {
    if (!spMenuRef.current) return;
    spMenuRef.current.classList.remove(styles.isOpen);
    setHoverControl(defaultHoverControl);
    setIsGrayDown(false); // グレーダウン状態をリセット
  }, [pathname]);

  const [allPosts, setAllPosts] = useState<Post[]>([]);
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`/posts_json/${dir}_posts.json`);
        if (!response.ok) {
          console.log(response);
          throw new Error();
        }
        const data: Post[] = await response.json();
        setAllPosts(data);
      } catch (error) {
        console.log(error);
        setAllPosts([]);
        return;
      }
    })();
  }, [dir]);

  const inputRef = useRef<HTMLInputElement>(null);
  const spMenuRef = useRef<HTMLDivElement>(null);

  const defaultHoverControl = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

  const [hoverControl, setHoverControl] =
    useState<{ link?: boolean; space?: boolean }[]>(defaultHoverControl);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!inputRef.current?.value) return;
    router.push(`/search?keyword=${inputRef.current.value}`);
  };

  const onClickMenuButton = () => {
    if (!spMenuRef.current) return;
    spMenuRef.current.classList.toggle(styles.isOpen);
  };

  const onClickFormOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    const box = event.currentTarget.parentElement;
    if (box) box.classList.add(styles.isOpen);
  };

  const onClickFormClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    const box = event.currentTarget.parentElement;
    if (box) box.classList.remove(styles.isOpen);
  };

  const onMouseLeaveLink = (index: number) => {
    setHoverControl((prev) =>
      prev.map((item, _index) => {
        if (index === _index) {
          return { ...item, link: false };
        } else {
          return item;
        }
      })
    );
    setIsGrayDown(false);
  };

  const onMouseOverSpace = (index: number) => {
    setHoverControl((prev) =>
      prev.map((item, _index) => {
        if (index === _index) {
          return { ...item, space: true };
        } else {
          return item;
        }
      })
    );
    setIsGrayDown(true);
  };

  const onMouseLeaveSpace = (index: number) => {
    setHoverControl((prev) =>
      prev.map((item, _index) => {
        if (index === _index) {
          return { ...item, space: false };
        } else {
          return item;
        }
      })
    );
    setIsGrayDown(false);
  };

  useEffect(() => {
    if (!spMenuRef.current) return;
    spMenuRef.current.classList.remove(styles.isOpen);
    setHoverControl(defaultHoverControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      {isGrayDown && <div className={styles.grayOverlay}></div>}
      <SPMenu spMenuRef={spMenuRef} />

      {/* <header className={styles.header} ref={headerRef}> */}
      <div
        className={styles.top}
        style={{ backgroundColor: isGrayDown ? "white" : "" }}
      >
        <div className={styles.inner}>
          <MyLink href="/" className={styles.logo}>
            <Image
              src="/logo/logo.svg"
              alt="AI総合研究所"
              width={245}
              height={62}
            />
          </MyLink>

          <nav className={styles.nav}>
            <ul className={styles.navList}>
              <li className={styles.navListLi}>
                <MyLink
                  href="/"
                  style={pathname === "/" ? { fontWeight: "bold" } : {}}
                >
                  ホーム
                </MyLink>
              </li>
              <li
                className={styles.navListLi}
                onMouseOver={handleMouseOverServices}
                onMouseLeave={handleMouseLeaveServices}
              >
                <MyLink
                  href="/business"
                  style={
                    pathname.split("/")[1] === "service"
                      ? { fontWeight: "bold" }
                      : {}
                  }
                >
                  サービス
                </MyLink>
                {showPopup && (
                  <PopupServiceMenu
                    onMouseOver={handleMouseOverServices}
                    onMouseLeave={handleMouseLeaveServices}
                  />
                )}
              </li>
              <li
                className={styles.navListLi}
                onMouseOver={() => {
                  onMouseOverLink(0, "case");
                }}
                onMouseLeave={() => {
                  onMouseLeaveLink(0);
                }}
              >
                <MyLink
                  href="/case"
                  style={
                    pathname.split("/")[1] === "case"
                      ? { fontWeight: "bold" }
                      : {}
                  }
                >
                  事例
                </MyLink>
                {hoverControl[0].link || hoverControl[0].space ? (
                  <HoverSpace
                    onMouseOver={() => {
                      onMouseOverSpace(0);
                    }}
                    onMouseLeave={() => {
                      onMouseLeaveSpace(0);
                    }}
                    firstCategoryName={"業種別カテゴリ"}
                    categories={industories}
                    allPosts={allPosts}
                    dir={dir}
                  />
                ) : null}
              </li>
              <li
                className={styles.navListLi}
                onMouseOver={() => {
                  onMouseOverLink(1, "article");
                }}
                onMouseLeave={() => {
                  onMouseLeaveLink(1);
                }}
              >
                <MyLink
                  href="/article"
                  style={
                    pathname.split("/")[1] === "article"
                      ? { fontWeight: "bold" }
                      : {}
                  }
                >
                  メソッド
                </MyLink>
                {hoverControl[1].link || hoverControl[1].space ? (
                  <HoverSpace
                    onMouseOver={() => {
                      onMouseOverSpace(1);
                    }}
                    onMouseLeave={() => {
                      onMouseLeaveSpace(1);
                    }}
                    firstCategoryName={"ニュース一覧"}
                    categories={articleBasicCategories}
                    allPosts={allPosts}
                    dir={dir}
                  />
                ) : null}
              </li>
              <li className={styles.navListLi}>
                <MyLink
                  href="/company-info"
                  style={
                    pathname.split("/")[1] === "company-info"
                      ? { fontWeight: "bold" }
                      : {}
                  }
                >
                  会社紹介
                </MyLink>
              </li>
            </ul>
          </nav>
          <div className={styles.box}>
            <button
              type="button"
              onClick={onClickFormOpen}
              className={styles.openButton}
            >
              <Image
                src="/icon/search-icon.svg"
                alt="検索フォームを開く"
                width={26}
                height={26}
              />
            </button>
            <form onSubmit={onSubmit} className={styles.searchForm}>
              <input type="text" placeholder="Search" ref={inputRef} />
              <button type="submit" className={styles.submitButton}>
                検索
              </button>
            </form>
            <button
              type="button"
              aria-label="検索フォームを閉じる"
              className={styles.closeButton}
              onClick={onClickFormClose}
            />
            {/*<a href="tel:0120-03-4150" className={styles.tel}>
              <div className={styles.telIcon}>
                <Image src="/icon/tel-icon.svg" alt="" width={15} height={16} />
              </div>
              <div>
                <p className={styles.telText1}>AI導入・活用のご相談</p>
                <p className={styles.telText2}>0120-03-4150</p>
              </div>
                </a>*/}
            <MyLink href="/mail-magazine" className={styles.contact}>
              メルマガ
            </MyLink>
            <MyLink href="/resources" className={styles.contact}>
              資料請求
            </MyLink>
            <MyLink href="/contact" className={styles.contact}>
              無料相談
            </MyLink>
          </div>
        </div>
        {/*<a className={styles.telBtn} href="tel:0120-03-4150">
          <Image src="/icon/tel-icon3.svg" alt="tel" width={21} height={21} />
              </a>*/}
        <button
          className={styles.menuBtn}
          aria-label="メニューボタン"
          onClick={onClickMenuButton}
        >
          <span>
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>
      </div>
      {/* <div className={styles.bottom}>
        <ul className={styles.bottomList}>
          {dir === "case" ? (
            <li className={styles.bottomListItem}>
              <a
                href="#"
                className={
                  hoverControl[1].link || hoverControl[1].space
                    ? clsx(styles.bottomListButton, styles.isActive)
                    : styles.bottomListButton
                }
                onClick={(event) => event.preventDefault()}
                onMouseOver={() => {
                  onMouseOverLink(0);
                }}
                onMouseLeave={() => {
                  onMouseLeaveLink(0);
                }}
              >
                業種別カテゴリ
              </a>
              {hoverControl[0].link || hoverControl[0].space ? (
                <HoverSpace
                  onMouseOver={() => {
                    onMouseOverSpace(0);
                  }}
                  onMouseLeave={() => {
                    onMouseLeaveSpace(0);
                  }}
                  firstCategoryName={"業種別カテゴリ"}
                  categories={industories}
                  allPosts={allPosts}
                  dir={dir}
                />
              ) : null}
            </li>
          ) : (
            categories?.map((item, index) => {
              return (
                <li className={styles.bottomListItem} key={item.name}>
                  <MyLink
                    href={`/${dir}/category/${item.category}`}
                    className={
                      hoverControl[index].link || hoverControl[index].space
                        ? clsx(styles.bottomListButton, styles.isActive)
                        : styles.bottomListButton
                    }
                    onMouseOver={() => {
                      onMouseOverLink(index);
                    }}
                    onMouseLeave={() => {
                      onMouseLeaveLink(index);
                    }}
                  >
                    {item.name}
                  </MyLink>
                  {hoverControl[index].link || hoverControl[index].space ? (
                    <HoverSpace
                      onMouseOver={() => {
                        onMouseOverSpace(index);
                      }}
                      onMouseLeave={() => {
                        onMouseLeaveSpace(index);
                      }}
                      firstCategoryName={item.name}
                      categories={item.secondCategories}
                      allPosts={allPosts}
                      dir={dir}
                    />
                  ) : null}
                </li>
              );
            })
          )}
        </ul> 
      </div> */}
      {/* <MyLink href="/contact" className={styles.companyLink}>
            掲載希望の企業様はこちら
            </MyLink> */}

      {/* </header> */}
    </>
  );
};
