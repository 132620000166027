export const newsCategories = [
  {
    name: "ニュース一覧",
    category: "all-news",
    description: "国内・海外の最新AIニュースをお届け。AI総合研究所が厳選したニュースまとめ一覧で、最新のトレンドと知見を得ましょう",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "技術革新",
        category: "tech-innovation",
        description:
          "AI技術革新の世界に飛び込む。AI総合研究所が厳選した記事で、最新のトレンドと知見を得ましょう",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "実践応用",
        category: "practical-applications",
        description:
          "AI実践応用の世界に飛び込む。AI総合研究所が厳選した記事で、最新のトレンドと知見を得ましょう",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "倫理と影響",
        category: "ethics-and-impact",
        description:
          "AI技術の倫理と影響の最新動向をキャッチ。AI総合研究所が厳選した記事で、最新のトレンドと知見を得ましょう",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "政策と規制",
        category: "policy-and-regulation",
        description:
          "AI技術の政策と規制の最新動向をキャッチ。AI総合研究所が厳選した記事で、最新のトレンドと知見を得ましょう",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "コミュニティ＆イベント",
        category: "community-and-events",
        description:
          "AIのコミュニティ＆イベントの最新動向をキャッチ。AI総合研究所が厳選した記事で、最新のトレンドと知見を得ましょう",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
];

export const articleCategories = [
  {
    name: "ChatGPT特集",
    category: "chatgpt",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "基本情報",
        category: "chatgpt-overview",
        description:
          "ChatGPTに関する基本情報を網羅。AI総合研究所が提供するChatGPT特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "使い方",
        category: "chatgpt-guidelines",
        description:
          "ChatGPTの使い方を解説。AI総合研究所が提供するChatGPT特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "プロンプト",
        category: "chatgpt-prompts",
        description:
          "ChatGPT活用に効果的なプロンプトを網羅。AI総合研究所が提供するChatGPT特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "開発-プログラミング",
        category: "chatgpt-development",
        description:
          "ChatGPTを活用した開発・プログラミングの情報を網羅。AI総合研究所が提供するChatGPT特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "追加機能",
        category: "chatgpt-features",
        description:
          "ChatGPTの追加機能を網羅。AI総合研究所が提供するChatGPT特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "エラー問題解決",
        category: "chatgpt-troubleshooting",
        description:
          "ChatGPTのエラー情報と解決方法を網羅。AI総合研究所が提供するChatGPT特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "その他",
        category: "chatgpt-other",
        description:
          "ChatGPTに関するその他情報を網羅。AI総合研究所が提供するChatGPT特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
  {
    name: "クラウド特集",
    category: "cloud",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "基本情報",
        category: "cloud-overview",
        description:
          "クラウド大手生成AI基盤に関する基本情報を網羅。AI総合研究所が提供するクラウド特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "使い方",
        category: "cloud-guidelines",
        description:
          "クラウド大手生成AI基盤の使用方法を解説。AI総合研究所が提供するクラウド特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "エラー問題解決",
        category: "cloud-troubleshooting",
        description:
          "クラウド大手生成AI基盤のエラー情報と解決方法を網羅。AI総合研究所が提供するクラウド特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "その他",
        category: "cloud-other",
        description:
          "クラウド大手生成AI基盤のその他情報を網羅。AI総合研究所が提供するクラウド特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
  {
    name: "画像・動画生成特集",
    category: "generative-image-movie",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "基本情報",
        category: "generative-image-movie-overview",
        description:
          "画像・動画生成の基本情報を網羅。AI総合研究所が提供する画像・動画生成特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "使い方",
        category: "generative-image-movie-guidelines",
        description:
          "画像・動画生成の使い方を網羅。AI総合研究所が提供する画像・動画生成特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "エラー問題解決",
        category: "generative-image-movie-troubleshooting",
        description:
          "画像・動画生成のエラー情報と解決方法を網羅。AI総合研究所が提供する画像・動画生成特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "その他",
        category: "generative-image-movie-other",
        description:
          "画像・動画生成のその他情報を網羅。AI総合研究所が提供する画像・動画生成特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
  {
    name: "AIお役立ち情報",
    category: "ai",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "基本情報",
        category: "ai-overview",
        description:
          "AIのお役立ち情報を網羅。AI総合研究所が提供するAIお役立ち特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "使い方",
        category: "ai-guidelines",
        description:
          "AIの汎用的な使い方を網羅。AI総合研究所が提供するAIお役立ち特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "開発-プログラミング",
        category: "ai-development",
        description:
          "AIの汎用的な開発方法を網羅。AI総合研究所が提供するAIお役立ち特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "エラー問題解決",
        category: "ai-troubleshooting",
        description:
          "AI導入でよくあるエラー情報や解決方法を網羅。AI総合研究所が提供するAIお役立ち特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "その他",
        category: "ai-other",
        description:
          "AI導入における汎用的な情報を網羅。AI総合研究所が提供するAIお役立ち特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
  {
    name: "DXお役立ち情報",
    category: "dx",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "基本情報",
        category: "dx-overview",
        description:
          "DXにおけるAI活用の基本情報を網羅。AI総合研究所が提供するDXお役立ち特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "使い方",
        category: "dx-guidelines",
        description:
          "DXにおけるAIの使い方を網羅。AI総合研究所が提供するDXお役立ち特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "その他",
        category: "dx-other",
        description:
          "DXにおける汎用的な情報を網羅。AI総合研究所が提供するDXお役立ち特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
];

export const serviceCategories = [
  {
    name: "おすすめサービス紹介",
    category: "recommend",
    description: "おすすめのサービス一覧を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIサービスの情報源です。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "AI",
        category: "recommend-ai",
        description:
          "最新のAI技術を活用したサービスを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIサービスの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "生成AI",
        category: "recommend-generative-ai",
        description:
          "最新のAI技術を活用した、おすすめの生成AIサービスを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIサービスの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "導入開発",
        category: "recommend-development",
        description:
          "最新のAI技術を活用した導入開発向けのサービスを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIサービスの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "チャットボット",
        category: "recommend-chatbot",
        description:
          "最新のAI技術を活用した、おすすめのチャットボットサービスを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIサービスの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
  {
    name: "AIツール",
    category: "ai-tool",
    description: "おすすめのサービス一覧を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIサービスの情報源です",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "画像",
        category: "ai-tool-image",
        description:
          "最新のAI技術を活用した画像ツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "デザイン",
        category: "ai-tool-design",
        description:
          "最新のAI技術を活用したデザインツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "動画",
        category: "ai-tool-video",
        description:
          "最新のAI技術を活用した動画ツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "業務効率",
        category: "ai-tool-business-efficiency",
        description:
          "最新のAI技術を活用した業務効率ツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "業務特化型",
        category: "ai-tool-business-specific",
        description:
          "最新のAI技術を活用した業務特化型ツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "音声",
        category: "ai-tool-audio",
        description:
          "最新のAI技術を活用した音声ツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "文書",
        category: "ai-tool-document",
        description:
          "最新のAI技術を活用した文書ツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "セールス・マーケティング",
        category: "ai-tool-sales-marketing",
        description:
          "最新のAI技術を活用したセールス・マーケツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "エンタメ",
        category: "ai-tool-entertainment",
        description:
          "最新のAI技術を活用したエンタメツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "開発",
        category: "ai-tool-development",
        description:
          "最新のAI技術を活用した開発ツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "チャットボット",
        category: "ai-tool-chatbot",
        description:
          "最新のAI技術を活用したチャットボットツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "データ",
        category: "ai-tool-data",
        description:
          "最新のAI技術を活用したデータツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "検索",
        category: "ai-tool-search",
        description:
          "最新のAI技術を活用した検索ツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "アニメ・漫画",
        category: "ai-tool-anime-manga",
        description:
          "最新のAI技術を活用したアニメ・漫画ツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "3D",
        category: "ai-tool-3d",
        description:
          "最新のAI技術を活用した3Dツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "教育・学習",
        category: "ai-tool-education-learning",
        description:
          "最新のAI技術を活用した教育・学習ツールを紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAIツールの情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
  {
    name: "外部委託",
    category: "outsourcing-services",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "開発・データ分析",
        category: "outsourcing-services-development-data-analysis",
        description:
          "最新のAI技術を活用した開発・データ分析の外部委託情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つ外部委託の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "コンサルティング",
        category: "outsourcing-services-consulting-service",
        description:
          "最新のAI技術を活用したコンサルの外部委託情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つ外部委託の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
  /*{
    name: "AI人材活用",
    category: "ai-talent-utilization",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "SES",
        category: "ai-talent-utilization-ses",
        description:
          "AI人材を対象としたSES業界の情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAI人材活用の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "フリーランサー",
        category: "ai-talent-utilization-fleelancer",
        description:
          "AI人材を対象としたフリーランサー業界の情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つAI人材活用の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },*/
  {
    name: "教育・研修",
    category: "education-and-training",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "生成AI",
        category: "education-and-training-generative-ai",
        description:
          "AIの教育・研修を目的とした生成AI領域の情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つ教育・研修の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "画像・動画生成",
        category: "education-and-training-generative-image-movie",
        description:
          "AIの教育・研修を目的とした画像・動画生成領域の情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つ教育・研修の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "AI",
        category: "education-and-training-ai",
        description:
          "AIの教育・研修を目的としたAI領域の情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つ教育・研修の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "DX",
        category: "education-and-training-dx",
        description:
          "AIの教育・研修を目的としたDX領域の情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つ教育・研修の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "データサイエンス",
        category: "education-and-training-data-science",
        description:
          "AIの教育・研修を目的としたデータサイエンス領域の情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つ教育・研修の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "その他",
        category: "education-and-training-other",
        description:
          "AIの教育・研修を目的とした幅広い情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つ教育・研修の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
  {
    name: "会社紹介",
    category: "company",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "会社一覧",
        category: "company-all",
        description:
          "AI導入を推し進めるの企業情報を紹介。AI総合研究所が厳選した、あなたの課題解決に役立つ企業の情報源です。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
  {
    name: "研究室特集",
    category: "lab",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
    secondCategories: [
      {
        name: "自然言語処理",
        category: "lab-nlp",
        description:
          "自然言語処理の最新情報を網羅。AI総合研究所が提供する研究室特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
      {
        name: "ディープラーニング",
        category: "lab-deeplearning",
        description:
          "ディープラーニングの最新情報を網羅。AI総合研究所が提供する研究室特集で、AI技術の最前線を学びましょう。",
        adImage1: "",
        adImage2: "",
        bgImage: "",
      },
    ],
  },
];

export const industories= [
  {
    name: "全般",
    category: "general",
    image: "https://aisouken.blob.core.windows.net/industory/category1.webp",
    description:
      "AI技術の導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "建設・建築",
    category: "construction-architecture",
    image: "https://aisouken.blob.core.windows.net/industory/category2.webp",
    description:
      "建設・建築業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "人材",
    category: "human-resources",
    image: "https://aisouken.blob.core.windows.net/industory/category3.webp",
    description:
      "人材業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "IT・システム開発",
    category: "it-system-development",
    image: "https://aisouken.blob.core.windows.net/industory/category4.webp",
    description:
      "IT業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "不動産",
    category: "real-estate",
    image: "https://aisouken.blob.core.windows.net/industory/category5.webp",
    description:
      "不動産業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "医療・福祉・介護",
    category: "healthcare-welfare-care",
    image: "https://aisouken.blob.core.windows.net/industory/category6.webp",
    description:
      "医療・福祉・介護業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "金融・保険",
    category: "finance-insurance",
    image: "https://aisouken.blob.core.windows.net/industory/category7.webp",
    description:
      "金融・保険業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "飲食・食品",
    category: "food-beverage",
    image: "https://aisouken.blob.core.windows.net/industory/category8.webp",
    description:
      "飲食・食品業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "教育",
    category: "education",
    image: "https://aisouken.blob.core.windows.net/industory/category9.webp",
    description:
      "教育業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "サービス",
    category: "services",
    image: "https://aisouken.blob.core.windows.net/industory/category10.webp",
    description:
      "サービス業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },

  {
    name: "製造",
    category: "manufacturing",
    image: "https://aisouken.blob.core.windows.net/industory/category11.webp",
    description:
      "製造業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "広告",
    category: "advertising",
    image: "https://aisouken.blob.core.windows.net/industory/category12.webp",
    description:
      "広告業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "自治体・公的機関",
    category: "local-government-public-institutions",
    image: "https://aisouken.blob.core.windows.net/industory/category13.webp",
    description:
      "自治体・公的機関業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "研究・製薬",
    category: "research-pharmaceuticals",
    image: "https://aisouken.blob.core.windows.net/industory/category14.webp",
    description:
      "研究・製薬業界のAI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "その他",
    category: "other",
    image: "https://aisouken.blob.core.windows.net/industory/category15.webp",
    description:
      "AI導入事例を幅広く紹介。AI総合研究所の事例から、あなたのビジネスに適したAI活用法を見つけましょう。",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
];

export const companyService = [
  {
    name: "AIマーケティングエージェント",
    url: "https://www.gen-ai-marketing.com/",
  },
  {
    name: "画像動画生成環境構築",
    url: "/business/generative-image-movie",
  },
  {
    name: "AI活用基盤構築",
    url: "/business/generative-ai-platform",
  },
  {
    name: "AIエージェント開発",
    url: "/business/package-ai-app",
  },
  {
    name: "Azure総合支援",
    url: "/business/azure",
  },
  {
    name: "Azure請求代行",
    url: "/business/azure/invoice-service",
  },
  {
    name: "生成AIコンサルティング",
    url: "/business/consulting-generative-ai",
  },
  {
    name: "AI活用研修",
    url: "/business/training",
  }
];

export const articleBasicCategories = [
  {
    name: "ChatGPT特集",
    category: "chatgpt-overview",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "クラウド特集",
    category: "cloud-overview",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "画像・動画生成特集",
    category: "generative-image-movie-overview",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "AIお役立ち情報",
    category: "ai-overview",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: "DXお役立ち情報",
    category: "dx-overview",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
  {
    name: ">>AIメソッドを入手",
    category: "../../resources",
    description: "",
    adImage1: "",
    adImage2: "",
    bgImage: "",
  },
];

export const postsCountPerPage = 10;
export const postsCountPerPageResources = 9;
